<!--
 * @Description: 确认费用弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 11:57:20
 * @LastEditTime: 2022-07-01 09:23:56
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="确认费用"
      :visible.sync="confirmFeeVisible"
      width="800px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="fee-content">
        <div class="switch-page-button">
          <el-button
            round
            :class="[clickIndex == index ? 'activeButton' : '']"
            v-for="(btn, index) in feeList"
            :key="index"
            @click="switchPage(btn, index)"
            >{{ btn.combinationName }}</el-button
          >
        </div>
        <div class="btn-box">
          <div class="left-box">
            <el-button type="primary" @click="addFee">添加费用</el-button>
            <el-button type="primary" @click="handleDelete">删除费用</el-button>
          </div>
          <div class="right-box">
            <e6-vr-select
              v-model="taxGivetype"
              :data="taxGiveTypeList"
              placeholder="收费方式"
              title="收费方式"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </div>
        </div>
        <template>
          <el-table
            border
            :data="feeInfoList"
            highlight-current-row
            show-summary
            :summary-method="getSummaries"
            @selection-change="handleDeleteionChange"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row, $index }">
                <el-input
                  v-if="column.fieldName == 'paidIn'"
                  placeholder="请输入收费金额"
                  v-model="row.paidIn"
                  @input="handleInput(row, $index)"
                ></el-input>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 添加费用弹框 -->
    <add-fee-dialog
      @updateFee="updateFee"
      :addFeeVisible="addFeeVisible"
      @closeDialog="closeDialog"
      :terminalId="terminalId"
      fixType="2"
    ></add-fee-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
// import { feeColumn } from "../tableColumn";
import { getUnconfirmedFee, confirmFee, findDownList } from "@/api";
import { printError } from "@/utils/util";
import AddFeeDialog from "./addFeeDialog.vue";
export default {
  name: "confirmFeeDialog",
  components: { AddFeeDialog },
  data() {
    return {
      btnList: [], //组合数组
      clickIndex: 0, //当前组合的index
      terminalId: "", //当前组合的终端id
      dialogLoading: false,
      columnData: [
        {
          fieldName: "fixCatName",
          display: true,
          fieldLabel: "收费项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "fixTypeName",
          display: true,
          fieldLabel: "收费类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyName",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "paidIn",
          display: true,
          fieldLabel: "收费金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      feeList: [], //获取的所有组合的费用
      feeInfoList: [],
      addFeeVisible: false,
      selColumnId: [], //选中的数据
      taxGivetype: "", //收费方式
      taxGiveTypeList: [] //收费方式
    };
  },
  props: ["basicInfo", "confirmFeeVisible", "feeTaxGiveType"],
  mixins: [base],
  computed: {
    totalAmount() {
      let total = 0;
      total = this.feeInfoList.reduce((total, currentValue) => {
        return total + currentValue.paidIn * 1;
      }, 0);
      return total * 1;
    }
  },
  watch: {
    confirmFeeVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.taxGivetype = this.feeTaxGiveType;
          this.getFee();
        }
      }
    }
  },
  mounted() {
    this.getTaxGiveType();
  },
  methods: {
    //获取未确认费用
    async getFee() {
      try {
        let res = await getUnconfirmedFee({
          taskItemId: this.basicInfo.itemId
        });
        if (res.code == "OK") {
          let feeList = res.data.feeList;
          if (feeList.length) {
            feeList.forEach(each => {
              each.feeInfoVOList.map(item => {
                item.chargeMoney = item.recMoney;
              });
            });
            this.feeList = feeList;
            this.feeInfoList = _.cloneDeep(this.feeList[0].feeInfoVOList);
            this.terminalId = _.cloneDeep(this.feeList[0].terminalId);
          }
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取收费方式
    async getTaxGiveType() {
      try {
        this.loading = true;
        let res = await findDownList(["modifyTaxGiveType"]);
        //收费方式
        this.taxGiveTypeList = this.getFreezeResponse(res, {
          path: "data.modifyTaxGiveType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击筛选框
    switchPage(item, index) {
      this.terminalId = item.terminalId;
      this.clickIndex = index;
      this.feeInfoList = item.feeInfoVOList;
    },
    //合计
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "总计";
          return;
        }
        if (index === 4) {
          sums[index] = this.totalAmount;
        }
      });
      return sums;
    },
    // 表格勾选时间
    handleDeleteionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.fixCatId;
      });
    },
    // //输入框检验
    handleInput(row, index) {
      let feeInfoVOList = this.feeList[this.clickIndex].feeInfoVOList;
      feeInfoVOList[index].paidIn = row.paidIn;

      // let curRow=feeInfoVOList.find((item)=>item.fixCatId==row.fixCatId&&item.fixType==row.fixType&&item.fixCatName==)
      // row.paidIn = row.paidIn * 1 > row.fixMoney ? row.fixMoney : row.paidIn;
    },
    //点击添加费用
    addFee() {
      this.addFeeVisible = true;
    },
    //添加费用
    updateFee(data) {
      data.map(item => {
        item.paidIn = item.fixMoney;
        item.terminalId = this.terminalId;
        // if (
        //   this.feeInfoList.findIndex(each => {
        //     return each.fixCatId == item.fixCatId;
        //   }) == -1
        // ) {
        this.feeInfoList.push(item);
        this.feeList[this.clickIndex].feeInfoVOList.push(item);
        // }
      });
    },
    //删除费用
    handleDelete() {
      this.feeInfoList = this.feeInfoList.filter(
        item => !this.selColumnId.some(ele => ele === item.fixCatId)
      );
      this.feeList[this.clickIndex].feeInfoVOList = this.feeList[
        this.clickIndex
      ].feeInfoVOList.filter(
        item => !this.selColumnId.some(ele => ele === item.fixCatId)
      );
    },
    handleConfirm() {
      if (!this.taxGivetype) {
        this.$message.warning("请选择收费方式");
        return;
      }

      this.feeList.map(each => {
        each.flag = each.feeInfoVOList.every(item => {
          item.paidIn = item.paidIn ? item.paidIn : 0;
          return item.paidIn * 1 >= 0;
        });
      });
      let feeInfoList = [];
      this.feeList.map(item => {
        feeInfoList = feeInfoList.concat(item.feeInfoVOList);
      });
      if (!feeInfoList.length) {
        this.$message.warning("费用不能为空");
        return;
      }
      if (!this.feeList.every(item => item.flag)) {
        this.$message.warning("请检查收费金额是否全部填写");
        return;
      }

      this.$confirm("是否确认费用？", "确认费用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirmFeeReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //确认费用
    async confirmFeeReq() {
      try {
        this.dialogLoading = true;
        let feeInfoList = [];
        this.feeList.map(item => {
          feeInfoList = feeInfoList.concat(item.feeInfoVOList);
        });
        let res = await confirmFee({
          taskItemId: this.basicInfo.itemId,
          feeInfoList,
          taxGivetype: this.taxGivetype
        });
        if (res.code == "OK") {
          this.$message.success("确认成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    closeDialog() {
      this.addFeeVisible = false;
    },
    handleClose() {
      this.$emit("handleClose", "confirmFeeVisible");
    }
  }
};
</script>
<style lang="scss" scoped>
.fee-content {
  .btn-box {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-top: 10px;
    justify-content: space-between;
    /deep/ .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
