<!--
 * @Description: 改约
 * @Author: ChenXueLin
 * @Date: 2021-08-30 11:36:51
 * @LastEditTime: 2022-09-13 18:06:13
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="改约"
    :visible="changeTimeDialog"
    width="500px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    v-loading="loading"
    custom-class="time-dialog"
  >
    <el-form
      label-width="130px"
      ref="setTimeForm"
      :model="setTimeForm"
      :rules="setTimeFormRulers"
    >
      <el-form-item label="工程师预约时间" prop="changeDate">
        <el-date-picker
          v-model="setTimeForm.changeDate"
          type="datetime"
          placeholder="选择日期"
          value-format="timestamp"
          popper-class="special"
          :picker-options="pickerOptions1"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { batchChangeItem } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "changeTime",
  components: {},
  data() {
    return {
      loading: false,
      setTimeForm: {
        changeDate: ""
      },
      setTimeFormRulers: {
        changeDate: [
          {
            required: true,
            message: "请选择跟进时间",
            trigger: ["blur", "change"]
          }
        ]
      },
      pickerOptions1: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      }
    };
  },
  props: ["changeTimeDialog", "itemIdList"],
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //点击确定新增
    handleSubmit() {
      this.$refs.setTimeForm.validate(valid => {
        if (valid) {
          this.createFollowTimeReq();
        }
      });
    },
    //改约请求
    async createFollowTimeReq() {
      try {
        this.loading = true;
        let res = await batchChangeItem({
          changeDate: this.setTimeForm.changeDate,
          itemIdList: this.itemIdList
        });
        if (res.code == "OK") {
          this.$message.success("改约成功");
          this.$emit("updateItemInfo");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose() {
      this.setTimeForm = {
        changeDate: ""
      };
      this.$refs.setTimeForm.resetFields();
      this.$emit("handleClose", "changeTimeDialog");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.time-dialog {
  .el-dialog__body {
    padding-top: 20px;
  }
}
</style>
