<!--
 * @Description: 任务项详情
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2022-11-24 13:56:58
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="'任务项状态:' + basicInfo.itemStatusName"
          >
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                @click="handleReport"
                v-if="basicInfo.reportMaterial == 1"
                >上报安装配件</el-button
              >
              <el-button
                type="primary"
                @click="handleReport"
                v-if="basicInfo.reportMaterial == 2"
                >上报安装耗材</el-button
              >
              <el-button
                type="primary"
                @click="handleAttr"
                v-if="
                  [8, 6, 7].includes(basicInfo.itemStatus) &&
                    $route.meta.operationIds.includes(123) &&
                    !(basicInfo.isHistory && basicInfo.sourceOrganization == 2)
                "
                >属性配置</el-button
              >
              <el-button
                type="primary"
                @click="handleAudit(1)"
                v-if="
                  (basicInfo.taskSecondClass == 1 ||
                    basicInfo.taskSecondClass == 2) &&
                    (basicInfo.itemStatus == 3 || basicInfo.itemStatus == 4) &&
                    $route.meta.operationIds.includes(71) &&
                    ((basicInfo.taskSource != 3 && basicInfo.taskSource != 5) ||
                      basicInfo.isGrayscaleWork)
                "
              >
                装车
              </el-button>
              <el-button
                type="primary"
                @click="handleAudit(2)"
                v-if="
                  basicInfo.taskSecondClass == 10 &&
                    (basicInfo.itemStatus == 3 || basicInfo.itemStatus == 4) &&
                    $route.meta.operationIds.includes(107)
                "
              >
                维修
              </el-button>
              <el-button
                type="primary"
                @click="handleAudit(3)"
                v-if="collectionBtn && $route.meta.operationIds.includes(106)"
              >
                补录信息
              </el-button>
              <el-button
                type="primary"
                @click="handleConfirmFee"
                v-if="
                  confirmBtn &&
                    $route.meta.operationIds.includes(70) &&
                    !basicInfo.isGrayscaleWork
                "
              >
                添加费用
              </el-button>

              <el-button
                type="primary"
                @click="handleAuditFee"
                v-if="
                  confirmBtn &&
                    $route.meta.operationIds.includes(103) &&
                    !basicInfo.isGrayscaleWork
                "
              >
                审核费用
              </el-button>
              <el-button
                type="primary"
                @click="confirmChaoweibao"
                v-if="basicInfo.itemStatus == 6 && basicInfo.isGrayscaleWork"
              >
                确认费用
              </el-button>
              <el-button
                type="primary"
                @click="handleAudit(3)"
                v-if="
                  basicInfo.taskSecondClass == 3 &&
                    basicInfo.itemStatus == 3 &&
                    basicInfo.isGrayscaleWork
                "
              >
                装车
              </el-button>
              <el-button
                type="primary"
                @click="handleAbnormal"
                v-if="
                  (basicInfo.itemStatus == 3 ||
                    basicInfo.itemStatus == 4 ||
                    basicInfo.itemStatus == 5) &&
                    $route.meta.operationIds.includes(81) &&
                    basicInfo.taskSource != 3 &&
                    basicInfo.taskSource != 5
                "
              >
                异常提交
              </el-button>
              <el-button
                type="primary"
                @click="changeEngineer('assgin')"
                v-if="
                  (basicInfo.itemStatus == 1 || basicInfo.itemStatus == 2) &&
                    $route.meta.operationIds.includes(73)
                "
              >
                指派工程师
              </el-button>
              <el-button
                type="primary"
                @click="changeEngineer('change')"
                v-if="
                  (basicInfo.itemStatus == 3 || basicInfo.itemStatus == 4) &&
                    $route.meta.operationIds.includes(73)
                "
              >
                更换工程师
              </el-button>
              <el-button type="primary" @click="handleItem(1)" v-has="120">
                撤回任务项
              </el-button>
              <el-button type="primary" @click="handleItem(2)" v-has="136">
                关闭任务项
              </el-button>
              <el-button type="primary" @click="handleItem(3)" v-has="121">
                改约
              </el-button>
            </template>
          </detail-title-content>

          <!-- 基本信息 -->
          <template>
            <div class="baseInfo-content-wrap">
              <div class="baseInfo-box basicForm">
                <div class="edit-title">基本信息</div>
                <el-form
                  label-width="140px"
                  label-position="right"
                  class="mbForm"
                  style="margin-top:10px;"
                >
                  <el-row>
                    <!-- <div class="col"> -->
                    <el-col :span="8">
                      <el-form-item label="工单ID">
                        {{ basicInfo.itemId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="调度任务ID">
                        {{ basicInfo.scheduleId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="调度信息ID">
                        {{ basicInfo.scheduleInfoId }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="任务类型">
                        {{ basicInfo.taskFirstClassName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="任务单号">
                        <el-button type="text" @click="toTaskDetail">
                          {{ basicInfo.taskNumber }}</el-button
                        >
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="客户名称">
                        {{ basicInfo.corpName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="场景名称">
                        {{ basicInfo.sceneName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8" v-show="basicInfo.taskSecondClass == 3">
                      <el-form-item label="新场景名称">
                        {{ basicInfo.newSceneName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="场景类型">
                        {{ basicInfo.sceneTypeName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="车牌颜色/地址">
                        {{
                          basicInfo.sceneType == 1
                            ? basicInfo.plateColorName
                            : basicInfo.sceneAddress
                        }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="指派人">
                        {{ basicInfo.assignorName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="指派时间">
                        {{ basicInfo.assignTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="完成时间">
                        {{ basicInfo.finishTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="作业时间">
                        {{ basicInfo.fixDate }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="工程师">
                        {{ basicInfo.engineerrpName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="工程师电话">
                        {{ basicInfo.engineerrpPhone }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="关闭任务项原因">
                        {{ basicInfo.closeReasonStr }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="关闭任务项备注">
                        {{ basicInfo.closeRemark }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="问题类型">
                        {{ basicInfo.questionClass }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="问题描述">
                        {{ basicInfo.quesName }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="故障原因及处理措施">
                        {{ basicInfo.mainBody }}/{{ basicInfo.quesReason }}/{{
                          basicInfo.faultClass
                        }}/{{ basicInfo.solution }}/{{ basicInfo.faultReason }}
                      </el-form-item>
                    </el-col>
                    <!-- <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="主体">
                        {{ basicInfo.mainBody }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="故障现象">
                        {{ basicInfo.quesReason }}
                      </el-form-item>
                    </el-col>
                    <el-col
                      :span="8"
                      v-if="
                        basicInfo.taskSecondClass == 10 ||
                          basicInfo.taskSecondClass == 11
                      "
                    >
                      <el-form-item label="处理措施">
                        {{ basicInfo.solution }}
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                      <el-form-item label="异常原因">
                        {{ basicInfo.abnormalReason }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 费用 start -->
              <div class="task-list-content">
                <div class="edit-title">费用</div>
                <el-table :data="feeList">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in feeColumn"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- 费用 end -->
              <!-- 质检记录 start -->
              <div class="task-list-content">
                <div class="edit-title">质检记录</div>
                <el-form
                  label-width="140px"
                  label-position="right"
                  class="mbForm"
                  style="margin-top:10px;"
                >
                  <el-row>
                    <div
                      v-if="
                        inspectionRecord.recordInfoList &&
                          inspectionRecord.recordInfoList.length
                      "
                    >
                      <div
                        v-for="item in inspectionRecord.recordInfoList"
                        :key="item.recordInfoId"
                      >
                        <el-col :span="8">
                          <el-form-item label="主体(质检员)：">
                            {{ item.mainBodyName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="故障点：">
                            {{ item.failPointName }}
                          </el-form-item>
                        </el-col>
                        <el-col :span="8">
                          <el-form-item label="故障责任归属：">
                            {{ item.responsibilityName }}
                          </el-form-item>
                        </el-col>
                      </div>
                    </div>
                    <el-col :span="8">
                      <el-form-item label="是否工艺纠正：">
                        <el-switch
                          v-model="inspectionRecord.isProcessCorrection"
                          :active-value="1"
                          :inactive-value="0"
                          disabled
                        >
                        </el-switch>
                        <!-- {{ inspectionRecord.isProcessCorrectionName }} -->
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="纠正内容：">
                        {{ inspectionRecord.processCorrection }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="异常纠正类型：">
                        {{ inspectionRecord.correctiveActionName }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
                <div
                  style="padding-left:15px;padding-right:15px;color:#606266;font-size:14px;"
                >
                  <div>质检凭证:</div>
                  <el-image
                    v-for="item in inspectionRecord.fileUrlList"
                    :key="item.fieldId"
                    style="width: 200px; height: 200px;margin-top:15px;margin-right:15px;"
                    :src="item.fileUrl"
                    :preview-src-list="previewUrlList"
                  ></el-image>
                </div>
              </div>
              <!-- 费用 end -->
              <!-- 安装要求 新装单才显示 start -->
              <div
                class="task-list-content"
                v-if="basicInfo.taskSecondClass == 1"
              >
                <div class="edit-title">安装要求</div>
                <el-table :data="installRequirement">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in InstallRequireColumn"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- 安装要求 end -->
              <!-- 安装明细 start -->
              <div class="task-list-content">
                <div class="edit-title">作业明细</div>
                <el-table :data="basicInfo.detailRespVOList" max-height="350">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in installDetailColumn"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!-- 安装明细 end -->
              <!-- 场景其它信息 start -->
              <div class="task-list-content">
                <div class="edit-title">场景其它信息</div>
                <el-table :data="taskItemOtherInfoRespVO">
                  <el-table-column
                    show-overflow-tooltip
                    v-for="(column, index) in sceneColumnData"
                    :key="index"
                    :prop="column.fieldName"
                    :label="column.fieldLabel"
                    :min-width="column.width"
                    :fixed="column.fixed"
                    :align="column.align"
                    header-align="center"
                  >
                  </el-table-column>
                </el-table>
              </div>
              <!--车辆检测结果 start -->
              <div
                class="task-list-content"
                v-show="
                  basicInfo.combinationInspectionResultRespVOS &&
                    basicInfo.combinationInspectionResultRespVOS.length
                "
              >
                <div class="edit-title">车辆检测结果</div>
                <div>
                  <el-tabs v-model="activeName">
                    <el-tab-pane
                      :label="item.combinationName"
                      :name="index + ''"
                      v-for="(item,
                      index) in basicInfo.combinationInspectionResultRespVOS"
                      :key="index"
                    >
                      <div style="padding:15px;">
                        车辆检测完成时间:
                        <span style="color:#46BFEA">
                          {{
                            basicInfo.combinationInspectionResultRespVOS[index]
                              .detectionFinishTime
                          }}
                        </span>
                      </div>
                      <el-table
                        :data="
                          basicInfo.combinationInspectionResultRespVOS[index]
                            .resultRespVOS
                        "
                      >
                        <el-table-column
                          show-overflow-tooltip
                          v-for="(column, index) in detectionResultColumn"
                          :key="index"
                          :prop="column.fieldName"
                          :label="column.fieldLabel"
                          :min-width="column.width"
                          :fixed="column.fixed"
                          :align="column.align"
                          header-align="center"
                        >
                          <template slot-scope="{ row }">
                            <span v-if="column.fieldName === 'inspectionName'">
                              <i
                                style="font-size:16px;color:red;"
                                v-if="row.isMust === 1"
                                >*</i
                              >
                              <i
                                class="e6-icon-check_line"
                                style="font-size:16px;color:green;"
                                v-if="row.inspectionResult === 1"
                              ></i>
                              <i
                                class="e6-icon-delete_line"
                                style="font-size:16px;color:red;"
                                v-if="row.inspectionResult === 0"
                              ></i>
                              {{ row[column.fieldName] }}
                            </span>
                            <span
                              v-else-if="
                                column.fieldName === 'resultValue' &&
                                  (row.inspectionCode == 'JCX0001.JCX0116' ||
                                    row.inspectionCode == 'JCX0001.JCX0115')
                              "
                            >
                              <el-image
                                style="width: 30px; height: 30px"
                                :src="row.result[0]"
                                class="resultImg"
                                :preview-src-list="[row.result[0]]"
                              ></el-image>
                            </span>
                            <span
                              v-else-if="
                                column.fieldName === 'resultValue' &&
                                  row.inspectionCode == 'JCX0114.JCX0001' &&
                                  row.resultImg &&
                                  row.resultImg.length
                              "
                            >
                              <div class="channel-img-content">
                                <div
                                  v-for="(item, index) in row.resultImg"
                                  :key="index"
                                >
                                  <el-image
                                    style="width: 30px; height: 30px"
                                    :src="item.url"
                                    class="resultImg"
                                    :preview-src-list="[item.url]"
                                  ></el-image>
                                  <div>{{ item.channel }}路</div>
                                </div>
                              </div>
                            </span>
                            <span v-else>{{ row[column.fieldName] }}</span>
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <!-- 空跑记录 start -->
              <div class="task-list-content">
                <div class="edit-title">空跑记录</div>
                <el-form
                  label-width="140px"
                  class="line-form"
                  v-for="(item, idx) in basicInfo.emptyRunList"
                  :key="idx"
                >
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="空跑原因：">
                        {{ item.emptyRunReason }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="工程师：">
                        {{ item.engineerrpName }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="5">
                      <el-form-item label="空跑时间：">
                        {{ item.emptyRunTime }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="空跑凭证：">
                        <el-image
                          style="width:30px;height:30px;margin-right:10px;"
                          :src="src"
                          :preview-src-list="item.emptyRunPhoneList"
                          v-for="src in item.emptyRunPhoneList"
                          :key="src"
                        >
                        </el-image>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <!-- 作业凭证 start -->
              <div class="task-list-content" v-if="imgList.length">
                <div class="edit-title">作业凭证</div>
                <div class="imgs">
                  <div
                    class="img-item"
                    v-for="(item, index) in imgList"
                    :key="index"
                  >
                    <e6-image
                      class="img"
                      :src="item.fileUrl"
                      :preview-src-list="[item.fileUrl]"
                    ></e6-image>
                    <div class="picture-name">{{ item.photoPositionName }}</div>
                    <div class="picture-time">{{ item.photoTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </section>
      <!-- 表格 end -->
    </div>
    <!-- 超维保费用弹框 -->
    <el-dialog
      v-dialogDrag
      title="超维保费用"
      :visible.sync="chaoweibaoVisible"
      width="600px"
      :before-close="
        () => {
          handleClose('chaoweibaoVisible');
        }
      "
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
    >
      <div class="image__preview">
        <el-image
          style="width: 100px; height: 100px"
          :src="feeInfo.billUrl"
          :preview-src-list="srcList"
        >
        </el-image>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button
          class="cancel"
          @click="
            () => {
              handleClose('chaoweibaoVisible');
            }
          "
          >取消</el-button
        >
        <el-button type="primary" @click="billPayment">账单支付</el-button>
      </div>
    </el-dialog>
    <!-- 审核弹框 -->
    <!-- 新装加装、以新换旧补录信息弹框 -->
    <new-add-dialog
      :basicInfo="basicInfo"
      :auditVisible="auditVisible"
      @handleClose="handleClose"
      @refresh="initForm"
      :dialogType="dialogType"
      v-if="
        [1, 2, 7, 19, 20].indexOf(basicInfo.taskSecondClass) > -1 &&
          auditVisible &&
          !basicInfo.isGrayscaleWork
      "
    ></new-add-dialog>
    <!-- 新装加装、以新换旧补录信息弹框 -->
    <add-dialog-new
      :basicInfo="basicInfo"
      :auditVisible="auditVisible"
      @handleClose="handleClose"
      @refresh="initForm"
      :dialogType="dialogType"
      v-if="
        [1, 2, 7, 19, 20].indexOf(basicInfo.taskSecondClass) > -1 &&
          auditVisible &&
          basicInfo.isGrayscaleWork
      "
    ></add-dialog-new>
    <!-- 重装 -->
    <reinstall-dialog
      :basicInfo="basicInfo"
      :auditVisible="auditVisible"
      @handleClose="handleClose"
      @refresh="initForm"
      v-else-if="basicInfo.taskSecondClass == 3 && auditVisible"
    ></reinstall-dialog>
    <!-- 其他工单补录弹框 -->
    <other-dialog
      :dialogType="dialogType"
      v-if="
        [10, 11, 12].indexOf(basicInfo.taskSecondClass) > -1 &&
          !basicInfo.isGrayscaleWork &&
          auditVisible
      "
      :basicInfo="basicInfo"
      :auditVisible="auditVisible"
      @handleClose="handleClose"
      @refresh="initForm"
    ></other-dialog>
    <new-other-dialog
      :dialogType="dialogType"
      v-if="
        [10, 11, 12].indexOf(basicInfo.taskSecondClass) > -1 &&
          basicInfo.isGrayscaleWork &&
          auditVisible
      "
      :basicInfo="basicInfo"
      :auditVisible="auditVisible"
      @handleClose="handleClose"
      @refresh="initForm"
    ></new-other-dialog>
    <!-- 确认费用弹框 -->
    <confirm-fee-dialog
      :basicInfo="basicInfo"
      @refresh="initForm"
      :confirmFeeVisible="confirmFeeVisible"
      @handleClose="handleClose"
      :feeTaxGiveType="feeTaxGiveType"
      v-if="confirmFeeVisible"
    ></confirm-fee-dialog>
    <!-- 异常完成输入 -->
    <abnormal-finish-dialog
      :abnormalFinishDialog="abnormalFinishDialog"
      abnormalType="3"
      :scheduleId="basicInfo.itemId"
      @handleClose="handleClose"
      @refreshData="initForm"
      v-if="abnormalFinishDialog"
    ></abnormal-finish-dialog>
    <!-- 改约弹框 -->
    <change-time
      :changeTimeDialog="changeTimeDialog"
      @handleClose="handleClose"
      @updateItemInfo="initForm"
      :itemIdList="[basicInfo.itemId]"
    ></change-time>
    <!-- 更换/指派工程师 -->
    <change-engineer
      :isChange="isChange"
      :changeType="changeType"
      @handleClose="handleClose"
      :basicInfo="basicInfo"
      :taskId="basicInfo.taskId"
      :customerId="basicInfo.corpId"
      :itemIdList="[basicInfo.itemId]"
      :hiddenTip="true"
    ></change-engineer>

    <!-- 关闭任务项 -->
    <close-task-item-dialog
      :closeTaskItemDialog="closeTaskItemDialog"
      @handleClose="handleClose"
      @refresh="getDetail"
      :dialogType="1"
      :itemIdList="[basicInfo.itemId]"
    ></close-task-item-dialog>
    <!-- 撤回任务项 -->
    <reason-write
      :batchReturnItemDialog="batchReturnItemDialog"
      @handleClose="handleClose"
      @refresh="getDetail"
      :itemIdList="[basicInfo.itemId]"
    ></reason-write>
    <!-- 上报配件 -->
    <reportDialog
      :reportVisible="reportVisible"
      :basicInfo="basicInfo"
      @closeDialog="handleClose"
      :isReportConsume="basicInfo.reportMaterial == 2 ? 1 : 0"
      v-if="basicInfo.reportMaterial == 1"
    ></reportDialog>
    <!-- 上报耗材 -->
    <reported-consumables
      :reportVisible="reportVisible"
      :basicInfo="basicInfo"
      @closeDialog="handleClose"
      :isReportConsume="basicInfo.reportMaterial == 2 ? 1 : 0"
      v-if="basicInfo.reportMaterial == 2"
    ></reported-consumables>
  </div>
</template>

<script>
import base from "@/mixins/base";
// import { exportXlsx } from "@/utils/util";
import detailTitleContent from "@/components/detailTitleContent";
import AbnormalFinishDialog from "@/components/workOrderManage/abnormalFinishDialog.vue";
import closeTaskItemDialog from "@/components/workOrderManage/closeTaskItemDialog.vue";
import reasonWrite from "@/components/workOrderManage/reasonWrite.vue";
import {
  getTaskItemDetail,
  getCredential,
  getMaintainFeeList,
  checkedFee,
  batchReturnItem,
  getOwpBill,
  selectPayment,
  queryInspectionRecord
} from "@/api";
import { printError } from "@/utils/util";
import newAddDialog from "./components/collectInformation/newAddDialog.vue";
import AddDialogNew from "./components/collectInformation/AddDialogNew.vue";
import otherDialog from "./components/collectInformation/otherDialog.vue";
import newOtherDialog from "./components/collectInformation/newOtherDialog.vue";
import reinstallDialog from "./components/collectInformation/reinstallDialog.vue";
import reportDialog from "./components/reportDialog/reportDialog.vue";
import {
  sceneColumnData,
  otherSceneColumnData,
  InstallRequireColumn,
  installDetailColumn1,
  installDetailColumn2,
  feeColumn,
  detectionResultColumn
} from "./tableColumn";
import ConfirmFeeDialog from "./components/confirmFeeDialog.vue";
import changeTime from "@/components/changeTime.vue";
import changeEngineer from "../taskListManage/detail/components/changeEngineer.vue";
import ReportedConsumables from "./components/reportedConsumables/reportedConsumables.vue";
// import eventBus from "@/bus";
export default {
  name: "ActionItemDetail",
  data() {
    return {
      loading: false,
      closeTaskItemDialog: false,
      batchReturnItemDialog: false,
      taskInspectionId: "",
      basicInfo: {}, //基本信息
      taskItemOtherInfoRespVO: [], //场景其它信息与作业凭证数据
      InstallRequireColumn: InstallRequireColumn, //安装要求表头
      detailRespVOList: [], //作业明细数据
      installRequirement: [],
      feeColumn: feeColumn, //费用表头
      auditVisible: false,
      demosrcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg"
      ],
      feeList: [], //费用数据
      inspectionRecord: {}, //质检记录
      // 费用确认
      confirmFeeVisible: false,
      imgList: [],
      feeTaxGiveType: "", //收款方式
      abnormalFinishDialog: false,
      dialogType: 1,
      /******改约*******/
      changeTimeDialog: false,
      isChange: false, //显示指派更换工程师弹框
      changeType: "assign", //默认指派
      chaoweibaoVisible: false, //超维保费用
      dialogLoading: false,
      feeInfo: {},
      srcList: [],
      reportVisible: false,
      detectionResultColumn,
      activeName: "0",
      previewUrlList: []
    };
  },
  mixins: [base],
  components: {
    detailTitleContent,
    AddDialogNew,
    newAddDialog,
    ConfirmFeeDialog,
    otherDialog,
    reinstallDialog,
    AbnormalFinishDialog,
    changeTime,
    changeEngineer,
    closeTaskItemDialog,
    reasonWrite,
    newOtherDialog,
    reportDialog,
    ReportedConsumables
  },
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  computed: {
    btnType() {
      let value;
      let arr = [1, 2, 3, 4];
      if (
        arr.some(item => {
          return item == this.basicInfo.itemStatus;
        })
      ) {
        value = "yellowBtn";
      }
      if (this.basicInfo.itemStatus == 5 || this.basicInfo.itemStatus == 6) {
        value = "blueBtn";
      }
      if ([7, 8, 9, 15].indexOf(this.basicInfo.itemStatus) > -1) {
        value = "greenBtn";
      }
      return value;
    },
    collectionBtn() {
      let flag = false;
      //重装、维修、巡检、拆卸,新装/加装异常完成只有补录信息
      //12拆卸19销售升级单20售后升级单
      let typeList = [1, 2, 3, 10, 11, 7];
      if (
        typeList.findIndex(item => {
          return item == this.basicInfo.taskSecondClass;
        }) > -1 &&
        this.basicInfo.itemStatus == 7
      ) {
        flag = true;
      }
      if (
        [12, 19, 20].findIndex(item => {
          return item == this.basicInfo.taskSecondClass;
        }) > -1 &&
        [3, 4, 7].findIndex(item => {
          return item == this.basicInfo.itemStatus;
        }) > -1
      ) {
        flag = true;
      }
      return flag;
    },
    confirmBtn() {
      let flag = false;
      let typeList = [3, 10, 11, 12];
      // 重装、维修、巡检、拆卸
      if (
        typeList.findIndex(item => {
          return item == this.basicInfo.taskSecondClass;
        }) > -1 &&
        this.basicInfo.itemStatus == 6
      ) {
        //其他单带审核有确认费用
        flag = true;
      }
      return flag;
    },
    installDetailColumn() {
      //作业明细表头
      let column = [];
      if ([1, 2, 7, 19, 20].indexOf(this.basicInfo.taskSecondClass) > -1) {
        column = installDetailColumn1;
      } else {
        column = installDetailColumn2;
      }
      return column;
    },
    //场景其他信息表头
    sceneColumnData() {
      let column;
      if (this.basicInfo.sceneType == 1 || this.basicInfo.sceneType == 4) {
        //车辆
        column = sceneColumnData;
      } else {
        column = otherSceneColumnData;
      }
      return column;
    }
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.itemId = hasParamsRoute.params.itemId;
      this.taskInspectionId = hasParamsRoute.params.taskInspectionId;

      if (this.itemId) {
        this.getDetail();
        this.getCredential();
        this.queryInspectionRecordReq();
      }
      // if (this.taskInspectionId) {

      // }
    },
    //跳转到任务单详情
    toTaskDetail() {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: this.basicInfo.taskId
        }
      });
    },
    //查询调度任务信息详情
    async getDetail() {
      try {
        this.loading = true;
        let res = await getTaskItemDetail({ itemId: this.itemId });
        this.basicInfo = this.getFreezeResponse(res, {
          path: "data"
        });
        this.installRequirement = this.basicInfo.itemInstallRequirementRespVO
          ? [this.basicInfo.itemInstallRequirementRespVO]
          : [];
        this.taskItemOtherInfoRespVO = this.basicInfo.taskItemOtherInfoRespVO
          ? [this.basicInfo.taskItemOtherInfoRespVO]
          : [];
        let combinationInspectionResultRespVOS = this.basicInfo
          .combinationInspectionResultRespVOS;
        combinationInspectionResultRespVOS.map(item => {
          item.resultRespVOS.map(ele => {
            if (ele.inspectionCode == "JCX0114.JCX0001" && ele.result.length) {
              ele.resultImg = [];
              ele.result.map(v => {
                let obj = {};
                obj.channel = v.split(";")[0];
                obj.url = v.split(";")[1];
                ele.resultImg.push(obj);
              });
            }
          });
        });
        // this.basicInfo.combinationInspectionResultRespVOS = combinationInspectionResultRespVOS;
        console.log(this.basicInfo.combinationInspectionResultRespVOS, "@@@@");
        this.getFee();
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询质检记录
    async queryInspectionRecordReq() {
      try {
        this.loading = true;
        let res = await queryInspectionRecord({
          itemId: this.itemId,
          taskInspectionId: this.taskInspectionId
        });
        this.inspectionRecord = this.getFreezeResponse(res, {
          path: "data"
        });
        this.previewUrlList = this.inspectionRecord.fileUrlList.map(
          item => item.fileUrl
        );
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取费用列表
    async getFee() {
      try {
        let res = await getMaintainFeeList({ taskItemId: this.itemId });
        this.feeList = res.data.array;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询凭证图片
    async getCredential() {
      try {
        this.imgList = [];
        let res = await getCredential({ itemId: this.itemId });
        let photoLocationListRespVOList = res.data.photoLocationListRespVOList;
        let imgList = [];
        for (let i = 0; i < photoLocationListRespVOList.length; i++) {
          imgList = imgList.concat(
            photoLocationListRespVOList[i].photoLocationList
          );
        }
        this.imgList = imgList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //上报安装配件
    handleReport() {
      this.reportVisible = true;
    },
    //点击属性配置
    handleAttr() {
      // this.setAttrVisible = true;
      if (this.basicInfo.isHistory) {
        if (this.basicInfo.sourceOrganization != 2) {
          this.routerPush({
            name: "taskListManage/attributeConfig",
            params: {
              refresh: true,
              itemId: this.basicInfo.itemId,
              itemStatus: this.basicInfo.itemStatus
            }
          });
        }
      } else {
        this.routerPush({
          name: "taskListManage/newAttributeConfig",
          params: {
            refresh: true,
            itemId: this.basicInfo.itemId,
            itemStatus: this.basicInfo.itemStatus
          }
        });
      }
    },
    //点击补录信息
    handleAudit(type) {
      this.auditVisible = true;
      this.dialogType = type;
    },
    /********超维保费用*************/
    //点击确认超维保费用
    confirmChaoweibao() {
      this.chaoweibaoVisible = true;
      this.getOwpBillReq();
    },

    //获取费用详情
    async getOwpBillReq() {
      this.dialogLoading = true;
      try {
        let res = await getOwpBill({
          jobId: this.basicInfo.itemId
        });
        if (res.code == "OK") {
          this.feeInfo = res.data;
          this.srcList = [res.data.billUrl];
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击账单支付
    async billPayment() {
      try {
        this.dialogLoading = true;
        let res = await selectPayment({ jobId: this.itemId, paymentType: 1 });
        if (res.code == "OK") {
          this.$message.success("确认费用成功");
          this.chaoweibaoVisible = false;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //点击确认费用
    handleConfirmFee() {
      this.confirmFeeVisible = true;
      this.feeTaxGiveType = this.feeList.length
        ? this.feeList[0].taxGiveType.toString()
        : "";
    },
    //审核费用
    handleAuditFee() {
      this.$confirm(
        "是否确认审核通过，审核通过后费用将不能再更改！",
        "审核费用",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.auditFeeReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //异常完成
    handleAbnormal() {
      this.abnormalFinishDialog = true;
    },

    //审核费用请求
    async auditFeeReq() {
      try {
        this.loading = true;
        let feeInfoList = [];
        this.feeList.map(item => {
          feeInfoList = feeInfoList.concat(item.feeInfoVOList);
        });
        let res = await checkedFee({
          taskItemId: this.basicInfo.itemId
        });
        if (res.code == "OK") {
          this.$message.success("费用审核成功");
          this.initForm();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose(name) {
      this[name] = false;
    },
    handleItem(type) {
      if (type == 1) {
        //撤回工单
        // this.$confirm("是否确认撤回任务项？", "撤回任务项", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning"
        // })
        //   .then(() => {
        //     this.batchReturnItemReq();
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: "info",
        //       message: "已取消"
        //     });
        //   });
        this.batchReturnItemDialog = true;
      }
      if (type == 2) {
        //关闭工单
        this.closeTaskItemDialog = true;
      }
      if (type == 3) {
        //改约
        this.changeTimeDialog = true;
      }
    },
    /*********撤回工单**************/
    //撤回工单请求
    async batchReturnItemReq() {
      try {
        let res = await batchReturnItem({
          itemIdList: [this.basicInfo.itemId]
        });
        if (res.code == "OK") {
          this.$message.success("撤回成功");
          this.initForm();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    /*********指派更换工程师**************/
    //点击更换/指派工程师
    changeEngineer(changeType) {
      this.changeType = changeType;
      this.isChange = true;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../../../assets/styles/index.scss";
.main-wrapper > .container-wrapper .content-view-wrapper .content-view {
  padding: 0px;
}

.baseInfo-content-wrap {
  width: 100%;
  .baseInfo-box {
    width: 100%;
    .edit-title {
      width: 100%;
      margin-top: 10px;
    }
    /deep/.el-form-item--mini.el-form-item,
    .el-form-item--small.el-form-item {
      margin: 0;
    }
    /deep/.el-form-item--mini .el-form-item__label {
      line-height: 40px;
    }
    /deep/.el-form-item--mini .el-form-item__content {
      line-height: 40px;
    }
    .col {
      height: 40px;
      border-bottom: 1px solid #f0f0f0;
    }
    .col2 {
      height: 80px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
  .task-list-content {
    margin-top: 20px;
    .label-button-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .operate-action {
        height: 48px;
        .iBtn {
          height: 28px;
          margin-right: 10px;
          color: #46bfea;
          font-size: 12px;
          line-height: 28px;
          font-style: normal;
          background-color: #daf2fb;
          text-align: center;
          padding: 0 10px;
          cursor: pointer;
          border-radius: 3px;
        }
      }
    }
    .el-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
  }
}
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 20px;
    overflow-y: auto;
    max-height: 600px;
    // .el-input {
    //   width: 220px;
    // }
  }
}
.imgs {
  display: flex;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  flex-wrap: wrap;
  flex-direction: row;
  // justify-content: space-between;
  .img-item {
    margin-bottom: 20px;
    margin-right: 15px;
    & > .img {
      width: 240px;
      height: 160px;
      border-radius: 20px;
    }
    .picture-name {
      margin-top: 20px;
      font-size: 16px;
      color: #5f5f5f;
      text-align: center;
    }
    .picture-time {
      font-size: 14px;
      color: #909195;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .picture-score {
      text-align: center;
      .el-input {
        width: 130px;
        margin: 0 auto;
      }
    }
  }
  .img-hidden {
    width: 240px;
    height: 0px;
  }
}
.image__preview {
  margin: 0 auto;
  width: 500px;
  height: 500px;
  .el-image {
    width: 500px !important;
    height: 500px !important;
    img {
      width: 500px !important;
      height: 500px !important;
    }
  }
}
.channel-img-content {
  display: flex;
  justify-content: center;
  div {
    margin-right: 20px;
  }
}
</style>
