<!--
 * @Description:撤回原因弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-20 15:22:04
 * @LastEditTime: 2022-07-01 09:21:49
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="撤回"
    :visible="batchReturnItemDialog"
    width="500px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    v-loading="dialogLoading"
    custom-class="createDispatchDialog"
  >
    <el-form
      :model="closeForm"
      :rules="abnormalFormRules"
      :inline="true"
      label-width="120px"
      ref="closeForm"
    >
      <el-form-item label="撤回任务项原因" prop="recallReason">
        <el-input
          placeholder="撤回任务项原因"
          v-model="closeForm.recallReason"
          maxlength="200"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSubmit"> 确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { printError } from "@/utils/util";
import { batchReturnItem } from "@/api";
import base from "@/mixins/base";
export default {
  name: "reasonWrite",
  components: {},
  data() {
    return {
      closeForm: {
        recallReason: ""
      },
      dialogLoading: false,
      abnormalFormRules: {
        recallReason: [
          {
            required: true,
            message: "请输入撤回原因",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["batchReturnItemDialog", "itemIdList"],
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    async batchReturnItemReq() {
      try {
        this.dialogLoading = true;
        let res = await batchReturnItem({
          itemIdList: this.itemIdList,
          ...this.closeForm
        });
        if (res.code == "OK") {
          this.$message.success("撤回成功");
          this.handleClose();
          this.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.closeForm = {
        recallReason: ""
      };
      this.$refs.closeForm.resetFields();
      this.$emit("handleClose", "batchReturnItemDialog");
    },
    handleSubmit() {
      this.$refs.closeForm.validate(valid => {
        if (valid) {
          this.batchReturnItemReq();
        }
      });
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.createDispatchDialog {
  .el-dialog__body {
    padding: 10px;
  }

  .el-input {
    width: 300px;
  }
  .til {
    font-size: 12px;
    color: #606266;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 10px;
  }
}
</style>
