<!--
 * @Description: 添加费用弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 14:33:52
 * @LastEditTime: 2022-07-01 09:23:47
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="添加费用"
    :visible.sync="addFeeVisible"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="loading"
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
  >
    <div class="searchForm">
      <el-form
        :searchForm="searchForm"
        :inline="true"
        class="editForm"
        ref="searchForm"
      >
        <el-form-item prop="fixCatName">
          <el-input
            v-model="searchForm.fixCatName"
            placeholder="条目名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="currencyId">
          <e6-vr-select
            v-model="searchForm.currencyId"
            :data="currencyTypeList"
            placeholder="币种"
            title="币种"
            clearable
          ></e6-vr-select>
        </el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form>
    </div>
    <div class="switch-page-button">
      <el-button
        round
        :class="[clickIndex == index ? 'activeButton' : '']"
        v-for="(btn, index) in btnList"
        :key="index"
        @click="switchPage(index, btn.id)"
        >{{ btn.statusName }}</el-button
      >
    </div>
    <template>
      <el-table
        border
        :data="tableData"
        highlight-current-row
        height="300px"
        ref="feeTable"
        :row-key="
          row => {
            return row.fixCatId;
          }
        "
        @selection-change="handleFee"
      >
        <el-table-column
          type="selection"
          width="50"
          :reserve-selection="true"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </template>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import { printError } from "@/utils/util";
// import eventBus from "@/bus";
import { getMaintainFee } from "@/api";
export default {
  name: "addFeeDialog",
  components: {},
  data() {
    return {
      btnList: [
        {
          id: 2,
          statusName: "合同费用"
        },
        {
          id: 1,
          statusName: "标准费用"
        }
      ],
      clickIndex: 0,
      searchForm: {
        feeType: 2,
        fixCatName: "", //条目名称
        currencyId: 1, //1是人民币，2是港币,客户ID和币种两者必传其一
        pageSize: 10,
        pageIndex: 1
      },
      currencyTypeList: [
        {
          id: 1,
          label: "人民币"
        },
        {
          id: 2,
          label: "港币"
        }
      ],
      currencycurrencyTypeList: [
        {
          id: 1,
          label: "人民币"
        },
        {
          id: 2,
          label: "港币"
        }
      ],
      columnData: [
        {
          fieldName: "fixCatName",
          display: true,
          fieldLabel: "费用条目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "fixTypeName",
          display: true,
          fieldLabel: "费用类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyName",
          display: true,
          fieldLabel: "币种",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fixMoney",
          display: true,
          fieldLabel: "金额",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      total: 0,
      tableData: [],
      loading: false,
      selecttList: [] //选中的费用
    };
  },
  props: ["addFeeVisible", "terminalId"],
  mixins: [base, listPage],
  computed: {},
  watch: {
    addFeeVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.clickIndex = 0;
          this.getMaintainFeeReq();
        }
      }
    }
  },
  mounted() {},
  methods: {
    //获取费用条目
    async getMaintainFeeReq() {
      try {
        this.loading = true;
        let res = await getMaintainFee({
          ...this.searchForm,
          terminalId: this.terminalId,
          fixType: 2
        });
        this.tableData = res.data.array;
        this.tableData.map(item => {
          item.paidIn = _.cloneDeep(item.fixMoney);
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.getMaintainFeeReq();
    },
    //点击筛选框
    switchPage(index, id) {
      this.clickIndex = index;
      this.searchForm.feeType = id;
      this.searchForm.pageIndex = 1;
      this.getMaintainFeeReq();
    },
    // 每页条数改变
    handleSizeChange(pageSize) {
      this.searchForm.pageIndex = 1;
      this.searchForm.pageSize = pageSize;
      this.getMaintainFeeReq();
    },
    // 页码改变
    handleCurrentChange(curPage) {
      this.searchForm.pageIndex = curPage;
      this.getMaintainFeeReq();
      this.scrollTop = 0;
    },
    //勾选数据
    handleFee(val) {
      this.selecttList = val;
    },
    resetData() {
      this.selecttList = [];
      this.searchForm = {
        feeType: 2,
        fixCatName: "",
        currencyId: 1,
        pageIndex: 1,
        pageSize: 10
      };
      this.$refs.feeTable.clearSelection();
      this.$refs.searchForm.resetFields();
    },
    //关闭弹框
    handleClose() {
      this.searchForm = {
        feeType: 2,
        fixCatName: "", //条目名称
        currencyId: 1, //1是人民币，2是港币,客户ID和币种两者必传其一
        pageSize: 10,
        pageIndex: 1
      };
      this.$emit("closeDialog", "addFeeVisible");
    },
    //点击确定
    handleConfirm() {
      if (!this.selecttList.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$emit("updateFee", _.cloneDeep(this.selecttList));
      this.resetData();
      this.handleClose();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.editForm {
  margin-top: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}
</style>
